import './App.scss';

import {
  ScrollingProvider,
  Section,
} from 'react-scroll-section';

import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Benefits from "./components/Benefits/Benefits";
import Gallery from "./components/Gallery/Gallery";
import Review from "./components/Review/Review";
import Questions from "./components/Questions/Questions";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">  
      <ScrollingProvider>
        <Header/>
        <Section id="home">
          <Home/>
        </Section>
        <Section id="about">
          <About/>
        </Section>
        <Section id="benefits">
          <Benefits/>
        </Section>
        <Section id="gallery">
          <Gallery/>
        </Section>
        <Section id="review">
          <Review/>
        </Section>
        <Section id="questions">
          <Questions/>
        </Section>
        <Section id="contact">
          <Contact/>
        </Section>
        <Footer/>
      </ScrollingProvider>
    </div>
  );
}

export default App;
