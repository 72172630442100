import './gallery.scss';
import React, { useState } from 'react';
import { Modal, Button , Carousel } from 'antd';
import 'antd/dist/antd.css';
import slideImage1 from '../../assets/gallery_img1.jpg';
import slideImage2 from '../../assets/gallery_img2.jpg';
import slideImage3 from '../../assets/gallery_img3.jpg';
import slideImage4 from '../../assets/gallery_img4.jpg';
import slideImage5 from '../../assets/gallery_img5.jpg';
import slideImage6 from '../../assets/gallery_img6.jpg';
import slideImage7 from '../../assets/gallery_img7.jpg';
import slideImage8 from '../../assets/gallery_img8.JPG';

import slidePlus from '../../assets/gallery_plus.svg';

function Gallery() {
  const [visible, setVisible] = useState(false);
  const [imgUrl, setImgUrl] = useState('');

  const showFullPhoto = (img) => {
    setImgUrl(img[Object.keys(img)[0]]);
    setVisible(true);
  }
  
  return (
    <div className="gallery" id="gallery" >
      <Modal
        title="Full photo"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1000}
      >
        <img src={imgUrl} className="gallery__fullPhoto" alt=""/>
      </Modal>
      <h2 className="gallery__title">
        Gallery
      </h2>
      <p className="gallery__text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
      </p>
      <Carousel className="gallery__carousel" 
           autoplay 
           centerMode={true}
           draggable={true}
           swipeToSlide={true}
           slidesToShow={3}
           responsive = {[
            {
              breakpoint: 600,
              settings: {
                centerMode: false,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]}>
            
        <div>
          <div className="gallery__slide-wrap">
            <div className="gallery__slide-inner" 
                 style={{'background': `url('${slideImage1}') center center / cover`}}>
                 <img className="gallery__slide-plus" 
                      alt="" 
                      src={slidePlus}
                      onClick={() => showFullPhoto({slideImage1})}/>
            </div>
          </div>
        </div>
        <div>
          <div className="gallery__slide-wrap">
            <div className="gallery__slide-inner" 
                 style={{'background': `url('${slideImage2}') center center / cover`}}>
                 <img className="gallery__slide-plus" 
                      alt="" 
                      src={slidePlus}
                      onClick={() => showFullPhoto({slideImage2})}/>
            </div>
          </div>
        </div>
        <div>
          <div className="gallery__slide-wrap">
            <div className="gallery__slide-inner" 
                 style={{'background': `url('${slideImage3}') center center / cover`}}>
                 <img className="gallery__slide-plus" 
                      alt="" 
                      src={slidePlus}
                      onClick={() => showFullPhoto({slideImage3})}/>
            </div>
          </div>
        </div>
        <div>
          <div className="gallery__slide-wrap">
            <div className="gallery__slide-inner" 
                 style={{'background': `url('${slideImage4}') center center / cover`}}>
                 <img className="gallery__slide-plus" 
                      alt="" 
                      src={slidePlus}
                      onClick={() => showFullPhoto({slideImage4})}/>
            </div>
          </div>
        </div>
        <div>
          <div className="gallery__slide-wrap">
            <div className="gallery__slide-inner" 
                 style={{'background': `url('${slideImage5}') center center / cover`}}>
                 <img className="gallery__slide-plus" 
                      alt="" 
                      src={slidePlus}
                      onClick={() => showFullPhoto({slideImage5})}/>
            </div>
          </div>
        </div>
        <div>
          <div className="gallery__slide-wrap">
            <div className="gallery__slide-inner" 
                 style={{'background': `url('${slideImage6}') center center / cover`}}>
                 <img className="gallery__slide-plus" 
                      alt="" 
                      src={slidePlus}
                      onClick={() => showFullPhoto({slideImage6})}/>
            </div>
          </div>
        </div>
        <div>
          <div className="gallery__slide-wrap">
            <div className="gallery__slide-inner" 
                 style={{'background': `url('${slideImage7}') center center / cover`}}>
                 <img className="gallery__slide-plus" 
                      alt="" 
                      src={slidePlus}
                      onClick={() => showFullPhoto({slideImage7})}/>
            </div>
          </div>
        </div>
        <div>
          <div className="gallery__slide-wrap">
            <div className="gallery__slide-inner" 
                 style={{'background': `url('${slideImage8}') center center / cover`}}>
                 <img className="gallery__slide-plus" 
                      alt="" 
                      src={slidePlus}
                      onClick={() => showFullPhoto({slideImage8})}/>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
}

export default Gallery;
