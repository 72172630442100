import logo from '../../../assets/logo.png';
import './fixedheader.scss';
import React from 'react';
import {
    useScrollSection
  } from 'react-scroll-section';
import {Burger , Menu } from '../../Burger/Burger';

function FixedHeader() { 
  const [open, setOpen] = React.useState(false);
  const node = React.useRef();

  const homeSection = useScrollSection('home');
  const aboutSection = useScrollSection('about');
  const benefitsSection = useScrollSection('benefits');
  const gallerySection = useScrollSection('gallery');
  const questionsSection = useScrollSection('questions');
  const contactSection = useScrollSection('contact');
  return (
    <div className="fixed-header">
        <nav className="fixed-header__nav fixed-header__nav_desktop">
            <ul className="fixed-header__list">
                <li className="fixed-header__item" 
                    onClick={homeSection.onClick} 
                    selected={homeSection.selected}>
                    <span className="fixed-header__link">
                        Home
                    </span>
                </li>
                <li className="fixed-header__item" 
                    onClick={aboutSection.onClick} 
                    selected={aboutSection.selected}>
                    <span className="fixed-header__link">
                        About Us
                    </span>
                </li>
                <li className="fixed-header__item"
                    onClick={benefitsSection.onClick} 
                    selected={benefitsSection.selected}>
                    <span className="fixed-header__link">
                        Benefits
                    </span>
                </li>
                <li className="fixed-header__item"
                    onClick={gallerySection.onClick} 
                    selected={gallerySection.selected}>
                    <span className="fixed-header__link">
                        Gallery
                    </span>
                </li>
            </ul>
        </nav>
        <img src={logo} className="fixed-header__logo" alt=""/>
        <nav className="fixed-header__nav fixed-header__nav_second fixed-header__nav_desktop">
            <ul className="fixed-header__list">
                <li className="fixed-header__item"
                    onClick={questionsSection.onClick} 
                    selected={questionsSection.selected}>
                    <span className="fixed-header__link">
                        FAQ
                    </span>
                </li>
                <li className="fixed-header__item"
                    onClick={contactSection.onClick} 
                    selected={contactSection.selected}>
                    <span className="fixed-header__link">
                        Contact Us
                    </span>
                </li>
            </ul>
        </nav>
        <div ref={node} className="fixed-header__burger">
            <Burger open={open} setOpen={setOpen} />
            <Menu open={open} setOpen={setOpen} />
        </div>
    </div>
  );
}

export default FixedHeader;
