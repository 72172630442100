import './preheader.scss';
import mailIcon from '../../../assets/mail_icon.svg';
import phoneIcon from '../../../assets/phone_icon.svg';

function PreHeader() {
  return (
    <div className="pre-header">
        <a className="pre-header__item" href="tel:+421777777777">
            <img src={phoneIcon} className="pre-header__icon" alt=""/>
            <span className="pre-header__text">
                +421 777 777 777
            </span>
        </a>
        <a className="pre-header__item" href="mailto:mail@gmail.com">
            <img src={mailIcon} className="pre-header__icon" alt=""/>
            <span className="pre-header__text">
                mail@gmail.com
            </span>
        </a>
    </div>
  );
}

export default PreHeader;
