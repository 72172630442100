import './questions.scss';
import { Collapse } from 'antd';
import {
  useScrollSection
} from 'react-scroll-section';

function Questions() {
  const contactSection = useScrollSection('contact');

    const { Panel } = Collapse;

    function callback(key) {
    console.log(key);
    }

    const text = `
    Interscroller – this banner will be inserted in the content (text)
    and will appear in the internal pages, not the home screens.
    It scrolls together with the screen scrolling as the natural part
    of the text.

    Features and requirements:
    Mobile is highly recommended.
    The banner is significantly
    less effective on desktop.
    Banner has 2 sizes:
    Small 300x250. Supported and in usage at the moment.
    Big 500x800. Gives the impression that banner is a screen
    takeover type. Will be released later in 2020.
    `;

    const genExtra = () => (
      <div className="questions__btn-right">
      </div>
    );
  
  return (
    <div className="questions" id="questions" >
      <h2 className="questions__title questions__title_desktop">
        Frequently asked questions
      </h2>
      <h2 className="questions__title questions__title_mobile">
        F. A. Q.
      </h2>
      <div className="questions__collapse-wrapper">
        <Collapse
          defaultActiveKey={['1']}
          onChange={callback}
          expandIconPosition='right'
          accordion={true}
          className="questions__collapse-part"
        >
          <Panel header="Question 1" extra={genExtra()} key="1">
            <div className="questions__item">{text}</div>
          </Panel>
          <Panel header="Question 2" key="2" extra={genExtra()}>
            <div className="questions__item">{text}</div>
          </Panel>
          <Panel header="Question 3" key="3" extra={genExtra()}>
            <div className="questions__item">{text}</div>
          </Panel>
          <Panel header="Question 4" key="4" extra={genExtra()}>
            <div className="questions__item">{text}</div>
          </Panel>
        </Collapse>
        <Collapse
          defaultActiveKey={['1']}
          onChange={callback}
          expandIconPosition='right'
          accordion={true}
          className="questions__collapse-part questions__collapse-part_desktop"
        >
          <Panel header="Question 1" key="1" extra={genExtra()}>
            <div className="questions__item">{text}</div>
          </Panel>
          <Panel header="Question 2" key="2" extra={genExtra()}>
            <div className="questions__item">{text}</div>
          </Panel>
          <Panel header="Question 3" key="3" extra={genExtra()}>
            <div className="questions__item">{text}</div>
          </Panel>
          <Panel header="Question 4" key="4" extra={genExtra()}>
            <div className="questions__item">{text}</div>
          </Panel>
        </Collapse>
      </div>
      

      <span className="questions__btn button" 
         onClick={contactSection.onClick} 
         selected={contactSection.selected}>
        Learn More
      </span>
    </div>
  );
}

export default Questions;
