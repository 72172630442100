import './home.scss';
import { Carousel } from 'antd';
import 'antd/dist/antd.css';
import {
  useScrollSection
} from 'react-scroll-section';

import initialBgHome from '../../assets/bg.png';
import bgHome2 from '../../assets/home_2.jpg';
import bgHome3 from '../../assets/home_3.JPG';

function Home() {
  const aboutSection = useScrollSection('about');
  
  return (
    <div className="home" id="home" >
      <Carousel autoplay draggable={true} swipeToSlide={true}>
        <div>
          <div className="home__slide-inner" style={{'background': `center center / cover no-repeat url(${initialBgHome})`}}>
          </div>
        </div>
        <div> 
          <div className="home__slide-inner" style={{'background': `center center / cover no-repeat url(${bgHome2})`}}></div>
        </div>
        <div>
          <div className="home__slide-inner" style={{'background': `center center / cover no-repeat url(${bgHome3})`}}></div>
        </div>
      </Carousel>
      <h1 className="home__title">
        Give your VAN a fresh look
      </h1>
      <span className="home__btn button" 
        onClick={aboutSection.onClick} 
          selected={aboutSection.selected}>
        Learn More
      </span>
    </div>
  );
}

export default Home;
