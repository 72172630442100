import styled from 'styled-components';
import React from 'react';
import './burger.scss';
import {
    useScrollSection
  } from 'react-scroll-section';

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #EFFFFA;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  z-index: 90;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 576px) {
      width: 100%;
    }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0D0C1D;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`

export const Menu = ({ open , setOpen}) => {
  const homeSection = useScrollSection('home');
  const aboutSection = useScrollSection('about');
  const benefitsSection = useScrollSection('benefits');
  const gallerySection = useScrollSection('gallery');
  const questionsSection = useScrollSection('questions');
  const contactSection = useScrollSection('contact');
  return (
    <StyledMenu open={open}>
      <nav className="burger__nav">
            <ul className="burger__list"
            onClick={() => setOpen(!open)}>
                
                <li className="burger__item"
                    onClick={homeSection.onClick} 
                    selected={homeSection.selected}>
                    <span className="burger__link">
                        Home
                    </span>
                </li>
                <li className="burger__item"
                    onClick={aboutSection.onClick} 
                    selected={aboutSection.selected}>
                    <span className="burger__link">
                        About Us
                    </span>
                </li>
                <li className="burger__item"
                    onClick={benefitsSection.onClick} 
                    selected={benefitsSection.selected}>
                    <span className="burger__link">
                        Benefits
                    </span>
                </li>
                <li className="burger__item"
                    onClick={gallerySection.onClick} 
                    selected={gallerySection.selected}>
                    <span className="burger__link">
                        Gallery
                    </span>
                </li>
                <li className="burger__item"
                    onClick={questionsSection.onClick} 
                    selected={questionsSection.selected}>
                    <span className="burger__link">
                        FAQ
                    </span>
                </li>
                <li className="burger__item"
                    onClick={contactSection.onClick} 
                    selected={contactSection.selected}>
                    <span className="burger__link">
                        Contact Us
                    </span>
                </li>
            </ul>
        </nav>
        
    </StyledMenu>
  )
}

const StyledBurger = styled.button`
  position: absolute;
  top: 30%;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 16px;
  height: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 100;

  &:focus {
    outline: none;
  }

  div {
    width: 100% ;
    height: 2px;
    background: ${({ open }) => open ? '#0D0C1D' : '#F40040'};
    border-radius: 0px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    :nth-child(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

export const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

// const useOnClickOutside = (ref, handler) => {
//   React.useEffect(() => {
//     const listener = event => {
//       if (!ref.current || ref.current.contains(event.target)) {
//         return;
//       }
//       handler(event);
//     };
//     document.addEventListener('mousedown', listener);

//     return () => {
//       document.removeEventListener('mousedown', listener);
//     };
//   },
//   [ref, handler],
//   );
// };