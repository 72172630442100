import './header.scss';
import PreHeader from './PreHeader/PreHeader';
import FixedHeader from './FixedHeader/FixedHeader';

function Header() {
  return (
    <div className="header">
      <PreHeader/>
      <FixedHeader/>
    </div>
  );
}

export default Header;
