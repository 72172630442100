import './about.scss';
import {useState} from 'react';
import {
  useScrollSection
} from 'react-scroll-section';


function About() {
  const benefitsSection = useScrollSection('benefits');

  const [showMoreMode, setShowMoreMode] = useState(false);

  return (
    <div className="about" id="about">
      <h2 className="about__title">
        About us
      </h2>
      <p className="about__text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
      <p className="about__text about__text_onlyDesk">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
      {showMoreMode ?
        <>
          <p className="about__text about__text_fade">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <span className="about__more"
                onClick={() => setShowMoreMode(false)}>
            Hide...
          </span>
        </>
        :
        <span className="about__more" 
              onClick={() => setShowMoreMode(true)}>
          Read more...
        </span>
      }
      <span className="about__btn button" 
            onClick={benefitsSection.onClick} 
            selected={benefitsSection.selected}>
        Learn more
      </span>
    </div>
  );
}

export default About;
