import './benefits.scss';
import benefitIcon from '../../assets/benefit.svg';
import benefitIcon1 from '../../assets/benefit(1).svg';
import benefitIcon2 from '../../assets/benefit(2).svg';
import benefitIcon3 from '../../assets/benefit(3).svg';
import benefitIcon4 from '../../assets/benefit(4).svg';
import benefitIcon5 from '../../assets/benefit(5).svg';
import benefitIcon6 from '../../assets/benefit(6).svg';
import benefitIcon7 from '../../assets/benefit(7).svg';
import {
    useScrollSection
  } from 'react-scroll-section';


function Benefits() {
    const gallerySection = useScrollSection('gallery');
  
  return (
    <div className="benefits" id="benefits" >
        <h2 className="benefits__title">
            Benefits
        </h2>
        <p className="benefits__text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit 
        </p>
        <div className="benefits__row">
            <div className="benefits__item">
                <img src={benefitIcon} className="benefits__img" alt=""/>
                <h4 className="benefits__name">
                    Benefit 1
                </h4>
            </div>
            <div className="benefits__item">
                <img src={benefitIcon1} className="benefits__img" alt=""/>
                <h4 className="benefits__name">
                    Benefit 2
                </h4>
            </div>
            <div className="benefits__item">
                <img src={benefitIcon2} className="benefits__img" alt=""/>
                <h4 className="benefits__name">
                    Benefit 3
                </h4>
            </div>
            <div className="benefits__item">
                <img src={benefitIcon3} className="benefits__img" alt=""/>
                <h4 className="benefits__name">
                    Benefit 4
                </h4>
            </div>
        </div>
        <div className="benefits__row benefits__row_2">
            <div className="benefits__item">
                <img src={benefitIcon4} className="benefits__img" alt=""/>
                <h4 className="benefits__name">
                    Benefit 5
                </h4>
            </div>
            <div className="benefits__item">
                <img src={benefitIcon5} className="benefits__img" alt=""/>
                <h4 className="benefits__name">
                    Benefit 6
                </h4>
            </div>
            <div className="benefits__item">
                <img src={benefitIcon6} className="benefits__img" alt=""/>
                <h4 className="benefits__name">
                    Benefit 7
                </h4>
            </div>
            <div className="benefits__item">
                <img src={benefitIcon7} className="benefits__img" alt=""/>
                <h4 className="benefits__name">
                    Benefit 8
                </h4>
            </div>
        </div>
        <span className="benefits__btn button"
            onClick={gallerySection.onClick} 
            selected={gallerySection.selected}>
            Learn more
        </span>
    </div>
  );
}

export default Benefits;
