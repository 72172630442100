import './contactform.scss';
import { Formik, Field, Form } from 'formik';
import emailjs from 'emailjs-com';
import { Modal , message } from 'antd';

function ContactForm() {

  function emailSuccess() {
    let secondsToGo = 4;
    const modal = Modal.success({
      title: 'Thank you!',
      content: `Your message was sent.`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  function emailError() {
    let secondsToGo = 4;
    const modal = Modal.error({
      title: 'We are so sorry!',
      content: 'Something went wrong! Please check your internet connection.',
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  function sendEmail(e) {
    e.preventDefault();
    if(e.target[0].value !== '' 
      && e.target[1].value !== '' 
      && e.target[2].value !== ''){
        const hide = message.loading('Email send in progress..', 0);
        setTimeout(hide, 2500);
        emailjs.sendForm('service_k7n8v1q', 'template_v731qvb', e.target, 'user_EmetKYmugDLUKYjngpY36')
        .then((result) => {
            console.log(result.text);
            emailSuccess();
        }, (error) => {
          emailError();
            console.log(error.text);
        });
    }else {
      message.error('Fill all the fields please before you submit!');
    }
    
  }

  return (
    <div className="form">
        <h3 className="form__title">
            Ask Us a Question
        </h3>
      <Formik
        initialValues={{
            firstName: '',
            email: '',
        }}
        onSubmit={(e) => console.log(e)}
        >
        <Form className="form__inner" onSubmit={sendEmail}>
            <Field 
              id="firstName" 
              className="form__field" 
              name="firstName" 
              placeholder="Name" />
            <Field
              id="email"
              name="email"
              placeholder="Email"
              type="email"
              className="form__field"
            />
            <Field
              as="textarea"
              id="message"
              name="message"
              placeholder="Your Question"
              className="form__field form__textarea"
            />

            <button type="submit" className="button form__btn">Send</button>
        </Form>
      </Formik>
  </div>
  );
}

export default ContactForm;
