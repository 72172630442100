import './footer.scss';
import footerPhoneIcon from '../../assets/footer_phone.svg';
import footerMailIcon from '../../assets/footer_mail.svg';
import footerLogo from '../../assets/logo.png';
import {
    useScrollSection
  } from 'react-scroll-section';

function Footer() {
  const homeSection = useScrollSection('home');
  const aboutSection = useScrollSection('about');
  const benefitsSection = useScrollSection('benefits');
  const gallerySection = useScrollSection('gallery');
  const questionsSection = useScrollSection('questions');
  const contactSection = useScrollSection('contact');
  
  return (
    <div className="footer" id="footer">
        <img src={footerLogo} className="footer__logo" alt="logo"/>
        <nav className="footer__nav">
            <ul className="footer__list">
                <li className="footer__item"
                    onClick={homeSection.onClick} 
                    selected={homeSection.selected}>
                    <span className="footer__link">
                        Home
                    </span>
                </li>
                <li className="footer__item"
                    onClick={aboutSection.onClick} 
                    selected={aboutSection.selected}>
                    <span className="footer__link">
                        About
                    </span>
                </li>
                <li className="footer__item"
                    onClick={benefitsSection.onClick} 
                    selected={benefitsSection.selected}>
                    <span className="footer__link">
                        Benefits
                    </span>
                </li>
                <li className="footer__item"
                    onClick={gallerySection.onClick} 
                    selected={gallerySection.selected}>
                    <span className="footer__link">
                        Gallery
                    </span>
                </li>
                <li className="footer__item"
                    onClick={questionsSection.onClick} 
                    selected={questionsSection.selected}>
                    <span className="footer__link">
                        FAQ
                    </span>
                </li>
                <li className="footer__item"
                    onClick={contactSection.onClick} 
                    selected={contactSection.selected}>
                    <span className="footer__link">
                        Contact
                    </span>
                </li>
            </ul>
        </nav>
        <div className="footer__contacts">
            <a className="footer__contact" href="tel:+421777777777">
                <img src={footerPhoneIcon}
                     className="footer__icon"
                     alt=""/>
                <span className="footer__text">
                    +421 777 777 777
                </span>
            </a>
            <a className="footer__contact" href="mailto:mail@gmail.com">
                <img src={footerMailIcon}
                     className="footer__icon"
                     alt=""/>
                <span className="footer__text">
                    mail@gmail.com
                </span>
            </a>
        </div>
    </div>
  );
}

export default Footer;
