import './review.scss';
import { Carousel } from 'antd';
import 'antd/dist/antd.css';
import quotesIcon from '../../assets/quotes.svg';
import rateIcon from '../../assets/rate.svg';
import searchIcon from '../../assets/review_search.svg';
import arrowIcon from '../../assets/arrow_red.svg';

import faceIcon1 from '../../assets/review_face_1.png';
import faceIcon2 from '../../assets/review_face_2.png';
import faceIcon3 from '../../assets/review_face_3.png';
import faceIcon4 from '../../assets/review_face_4.png';

function Review() {
  
  return (
    <div className="review" id="review" >
      <h2 className="review__title">
        Our clients say about us
      </h2>
      <Carousel autoplay draggable={true} 
        swipeToSlide={true} 
        slidesToShow={3} 
        dots={false} 
        arrows={true}
        responsive = {[
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]}>
        <div>
          <div className="review__slide-inner">
              <div className="review__item">
                <div className="review__card">
                    <div className="review__card-top">
                        <div className="review__images">
                            <img className="review__quotes" src={quotesIcon} alt=""/>
                            <img className="review__stars" src={rateIcon} alt=""/>
                        </div>
                        <p className="review__extra-text">
                            Lorem ipsum dolor sit
                        </p>
                    </div>
                    <p className="review__content">
                        amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea  
                        &nbsp;<a className="review__link" href="/">read more</a>
                    </p>
                    <img src={searchIcon} className="review__icon" alt=""/>
                </div>
                <div className="review__person">
                    <img src={faceIcon1} className="review__face" alt=""/>
                    <div className="review__credentials">
                        <h5 className="review__name">
                            Amanda Smith
                        </h5>
                        <p className="review__time">
                            1 month ago
                        </p>
                    </div>
                </div>
              </div>
          </div>
        </div>
        <div>
          <div className="review__slide-inner">
              <div className="review__item">
                <div className="review__card">
                    <div className="review__card-top">
                        <div className="review__images">
                            <img className="review__quotes" src={quotesIcon} alt=""/>
                            <img className="review__stars" src={rateIcon} alt=""/>
                        </div>
                        <p className="review__extra-text">
                            Lorem ipsum dolor sit
                        </p>
                    </div>
                    <p className="review__content">
                        amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea  
                        &nbsp;<a className="review__link" href="/">read more</a>
                    </p>
                    <img src={searchIcon} className="review__icon" alt=""/>
                </div>
                <div className="review__person">
                    <img src={faceIcon2} className="review__face" alt=""/>
                    <div className="review__credentials">
                        <h5 className="review__name">
                            Amanda Smith
                        </h5>
                        <p className="review__time">
                            1 month ago
                        </p>
                    </div>
                </div>
              </div>
          </div>
        </div>
        <div>
          <div className="review__slide-inner">
              <div className="review__item">
                <div className="review__card">
                    <div className="review__card-top">
                        <div className="review__images">
                            <img className="review__quotes" src={quotesIcon} alt=""/>
                            <img className="review__stars" src={rateIcon} alt=""/>
                        </div>
                        <p className="review__extra-text">
                            Lorem ipsum dolor sit
                        </p>
                    </div>
                    <p className="review__content">
                        amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea  
                        &nbsp;<a className="review__link" href="/">read more</a>
                    </p>
                    <img src={searchIcon} className="review__icon" alt=""/>
                </div>
                <div className="review__person">
                    <img src={faceIcon3} className="review__face" alt=""/>
                    <div className="review__credentials">
                        <h5 className="review__name">
                            Amanda Smith
                        </h5>
                        <p className="review__time">
                            1 month ago
                        </p>
                    </div>
                </div>
              </div>
          </div>
        </div>
        <div>
          <div className="review__slide-inner">
              <div className="review__item">
                <div className="review__card">
                    <div className="review__card-top">
                        <div className="review__images">
                            <img className="review__quotes" src={quotesIcon} alt=""/>
                            <img className="review__stars" src={rateIcon} alt=""/>
                        </div>
                        <p className="review__extra-text">
                            Lorem ipsum dolor sit
                        </p>
                    </div>
                    <p className="review__content">
                        amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea  
                        &nbsp;<a className="review__link" href="/">read more</a>
                    </p>
                    <img src={searchIcon} className="review__icon" alt=""/>
                </div>
                <div className="review__person">
                    <img src={faceIcon4} className="review__face" alt=""/>
                    <div className="review__credentials">
                        <h5 className="review__name">
                            Amanda Smith
                        </h5>
                        <p className="review__time">
                            1 month ago
                        </p>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </Carousel>
      <div className="review__arrows">
        <img src={arrowIcon} 
          className="review__arrow review__arrow_prev slick-arrow slick-prev" 
          alt=""/>
        <img src={arrowIcon} 
          className="review__arrow review__arrow_next slick-arrow slick-next" 
          alt=""/>
      </div>
    </div>
  );
}

export default Review;
