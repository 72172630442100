import './contact.scss';
import ContactForm from '../ContactForm/ContactForm';

function Contact() {

  return (
    <div className="contact" id="contact" >
      <div className="contact__info">
        <h2 className="contact__title">
            Contact us
        </h2>
        <div className="contact__content">
            <div className="contact__main">
                <div className="contact__item">
                    <h4 className="contact__name">
                        Adsress
                    </h4>
                    <a href="/" className="contact__description">
                        Somewhere in the world <br/>
                        12345 City
                    </a>
                </div>
                <div className="contact__item">
                    <h4 className="contact__name">
                        Phone
                    </h4>
                    <a href="tel:+380631671058" className="contact__description">
                        +380631671058
                    </a>
                </div>
                <div className="contact__item">
                    <h4 className="contact__name">
                        Email
                    </h4>
                    <a href="mailto:mail@gmail.com" className="contact__description">
                        mail@gmail.com
                    </a>
                </div>
                
            </div>
            <div className="contact__hours">
                <h4 className="contact__hours-name">
                    Working Hours:
                </h4>
                <p className="contact__time">
                    Mon:9am – 5:30pm <br/>
                    Tue: 9am – 5:30pm<br/>
                    Wed: 9am – 5:30pm<br/>
                    Thur: 9am – 5:30pm<br/>
                    Fri: 9am – 5:30pm<br/>
                    Sat: Closed<br/>
                    Sun: Closed<br/>
                </p>
            </div>
        </div>
      </div>
      <div className="contact__form">
            <ContactForm/>
      </div>
    </div>
  );
}

export default Contact;
